<template>
    <!--<b-modal :title="opt.title +' statistics'" v-model="showModal" @change="onChange()">-->
    <b-modal v-model="showModal" @change="onChange()">
        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5>{{opt.title}} statistics</h5>
            <font-awesome-icon icon="times" size="lg" @click="close()" :style="modalX" />
        </template>
        <b-row class="border-bottom border-secondary">
            <b-col cols="4">

            </b-col>
            <b-col cols="2">
                <strong>{{stat.unit}}</strong>
            </b-col>
            <b-col cols="6">
                <strong>Time</strong>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col cols="2">
                Day
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                min:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.day.minValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.day.minTime}}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">

            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                max:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.day.maxValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.day.maxTime}}
            </b-col>
        </b-row>
        <b-row class="border-bottom border-secondary">
            <b-col cols="2">

            </b-col>
            <b-col cols="2">
                avg:
            </b-col>
            <b-col cols="2">
                {{stat.day.avgValue}}
            </b-col>
            <b-col cols="6">

            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col cols="2">
                Month
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                min:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.month.minValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.month.minTime}}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">

            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                max:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.month.maxValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.month.maxTime}}
            </b-col>
        </b-row>
        <b-row class="border-bottom border-secondary">
            <b-col cols="2">

            </b-col>
            <b-col cols="2">
                avg:
            </b-col>
            <b-col cols="2">
                {{stat.month.avgValue}}
            </b-col>
            <b-col cols="6">

            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col cols="2">
                Year
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                min:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.year.minValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.year.minTime}}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">

            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                max:
            </b-col>
            <b-col cols="2" class="border-bottom border-secondary">
                {{stat.year.maxValue}}
            </b-col>
            <b-col cols="6" class="border-bottom border-secondary">
                {{stat.year.maxTime}}
            </b-col>
        </b-row>
        <b-row class="border-bottom border-secondary">
            <b-col cols="2">

            </b-col>
            <b-col cols="2">
                avg:
            </b-col>
            <b-col cols="2">
                {{stat.year.avgValue}}
            </b-col>
            <b-col cols="6">

            </b-col>
        </b-row>
        <template v-slot:modal-footer="{}">
            <div></div>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: 'statModal',
        props: ['opt', 'statistics', 'showStatistics'],
        computed: {
            showModal: {
                get: function () {
                    return this.showStatistics
                },
                set: function (newValue) { return newValue }
            },
            modalX() {
                return {
                    'cursor': 'pointer',
                    'z-index': 100,
                    'color': '#ff9900'
                }

            },
            stat() {
                let stat = {}
                let content = {
                    avgValue: '-',
                    minValue: '-',
                    minTime: 'not avalaible',
                    maxValue: '-',
                    maxTime: 'not avalaible'
                }
                if (typeof this.statistics.day === 'undefined' || typeof this.statistics.day.avgValue === 'undefined') {
                    stat.day = content
                } else {
                    stat.day = {
                        avgValue: Math.round(this.statistics.day.avgValue * 100) / 100,
                        minValue: Math.round(this.statistics.day.minValue * 100) / 100,
                        minTime: new Date(this.statistics.day.minTime).toLocaleString(),
                        maxValue: Math.round(this.statistics.day.maxValue * 100) / 100,
                        maxTime: new Date(this.statistics.day.maxTime).toLocaleString()
                    }
                }
                if (typeof this.statistics.month === 'undefined' || typeof this.statistics.month.avgValue === 'undefined') {
                    stat.month = content
                } else {
                    stat.month = {
                        avgValue: Math.round(this.statistics.month.avgValue * 100) / 100,
                        minValue: Math.round(this.statistics.month.minValue * 100) / 100,
                        minTime: new Date(this.statistics.month.minTime).toLocaleString(),
                        maxValue: Math.round(this.statistics.month.maxValue * 100) / 100,
                        maxTime: new Date(this.statistics.month.maxTime).toLocaleString()
                    }
                }
                if (typeof this.statistics.year === 'undefined' || typeof this.statistics.year.avgValue === 'undefined') {
                    stat.year = content
                } else {
                    stat.year = {
                        avgValue: Math.round(this.statistics.year.avgValue * 100) / 100,
                        minValue: Math.round(this.statistics.year.minValue * 100) / 100,
                        minTime: new Date(this.statistics.year.minTime).toLocaleString(),
                        maxValue: Math.round(this.statistics.year.maxValue * 100) / 100,
                        maxTime: new Date(this.statistics.year.maxTime).toLocaleString()
                    }
                }
                if (typeof this.statistics.unit === 'undefined') {
                    stat.unit = ''
                } else {
                    stat.unit = this.statistics.unit
                }
                return stat
            }
        },
        methods: {
            onChange() {
                this.$emit('update:showStatistics', false)
            }
        }
    }
</script>

<style scoped>
</style>